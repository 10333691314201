/* eslint-disable */

import * as React from 'react'
import classnames from 'classnames'
import DemoCard from 'components/DemoCard'
import * as styles from './index.module.less'

const { useState } = React;

const DemoCardList = ({
  className,
  dataSource,
}) => {
  const [state, setState] = useState({
    current: null
  });

  const handleClick = (index) => {
    if(index === state.current) {
      setState({...state, current: null})
    } else {
      setState({...state, current: index})
    }
  }

  return (
    <div className={classnames(styles.DemoCardList, className)}>
      {dataSource?.map((item, index) => <DemoCard 
        className="demo-card-item"
        isopen={state.current === index}
        onClick={() => handleClick(index)}
        key={index}
        iconUrl={item.iconUrl}
        title={item.title}
        descTitle={item.descTitle}
        descList={item.descList}
        buttonList={item.buttonList} />)}
    </div>
  );
}

export default DemoCardList;

/* eslint-disable no-new */