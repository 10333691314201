/* eslint-disable */
import * as React from 'react'
import classnames from 'classnames'
import Button, { ButtonGroup, ButtonGroupAlign } from 'components/Base/Button'
import * as styles from './index.module.less'

const DemoCard = ({
  className,
  title,
  iconUrl,
  iconAlt,
  descTitle = '',
  descList = [],
  buttonList = [],
  isopen,
  onClick = () => {},
}) => {
  return (
    <div className={classnames(styles.DemoCard, isopen ? styles.open : '', className)}>
      <div className="DemoCard-hd" onClick={() => onClick()}>
        {iconUrl && <img className="icon" src={iconUrl} alt={iconAlt} />}
        <h3>{title}</h3>
        <hr />
        <i className="arrow"></i>
      </div>
      <div className="DemoCard-bd">
        {descTitle && <h4>{descTitle}</h4>}
        <ul>
          {descList?.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
        <ButtonGroup className="mt-[20px]" align={ButtonGroupAlign.Center}>
          {buttonList?.map((item) => (
            <Button key={item.text} btnType={item.type || 'primary'} href={item.href} target={item.target}>
              {item.text}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    </div>
  )
}

export default DemoCard
/* eslint-disable no-new */
